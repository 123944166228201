import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Reference } from '../models/reference.model';
import { ReferenceService } from '../services/reference.service';

export const referenceResolver: ResolveFn<BehaviorSubject<Reference | null>> = (route) => {
  const referenceId = route.params['referenceId'];
  const referenceService = inject(ReferenceService);

  const referenceSubject = new BehaviorSubject<Reference | null>(null);

  if (!referenceId) {
    console.error('No referenceId found in route params');
    referenceSubject.next(null);
    return of(referenceSubject);
  }

  console.log('Resolver: Fetching reference for ID:', referenceId);

  referenceService.getReference(referenceId).pipe(
    tap((reference) => {
      console.log('Resolver: Reference fetched:', reference);
      referenceSubject.next(reference);
    }),
    catchError((error) => {
      console.error('Resolver: Error fetching reference:', error);
      referenceSubject.next(null);
      return of(null);
    })
  ).subscribe();
  return of(referenceSubject);
};
