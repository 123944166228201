import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'prime-loading',
  standalone: true,
  imports: [CommonModule],
  template: `<div class="container flex h-screen">
    <div class="flex items-center justify-center m-auto">
      <div
        class="border-t-transparent border-solid animate-spin  rounded-full border-brand-secondary border-4 h-6 w-6"
      ></div>
      <p *ngIf="showText" class="ml-3 text-gray-600">Loading...</p>
    </div>
  </div>`,
  styles: [],
})
export class LoadingComponent {
  @Input() showText = true;
}
