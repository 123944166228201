<button
  [disabled]="disabled"
  [routerLink]="route"
  class="button-base flex flex-col rounded-md w-full h-full"
  [class.disabled]="disabled"
  [class.group]="!disabled"
>
  <div class="w-full flex flex-grow items-center justify-start p-4 lg:p-6">
    <p class="text-lg text-gray-750 group-active:text-white leading-tight text-left">
      {{ title }}
    </p>
  </div>

  <div
    class="w-full h-0.5 shrink-0 bg-gradient-to-r from-brand-primary to-brand-secondary"
  ></div>

  <div
    class="flex w-full h-10 items-center justify-end space-x-2 px-4 short:hidden bg-gray-300/10 rounded-b-md group-active:rounded-b-md"
  >
    <p
      class="text-brand-primary group-active:text-brand-secondary text-ss leading-none uppercase"
    >
      {{ buttonTitle }}
    </p>
    <img src="assets/icons/icon-continue.svg" alt="" class="w-4 h-4" />
  </div>
</button>
