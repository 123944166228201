import { Route } from '@angular/router';
import { authGuard, LoginComponent, loginFallbackResolver, LogoutComponent } from '@prime-platform/auth';
import {
  NotFoundComponent,
  referenceResolver,
  resourceResolver,
  ViewerComponent,
} from '@prime-platform/shared';

export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@prime-platform/dashboard').then((m) => m.DashboardModule),
      canActivate: [authGuard]
  },
  {
    path: 'scientific-platform',
    loadChildren: () =>
      import('@prime-platform/scientific-platform').then(
        (m) => m.ScientificPlatformModule
      ),
      canActivate: [authGuard],
    data: {
      sectionsTitle: 'Indications',
      pillarsTitle: 'Pillars',
      statementsTitle: 'Statements',
      statementTileButtonText: 'View supporting evidence statements',
    },
  },
  {
    path: 'references',
    loadChildren: () =>
      import('@prime-platform/references').then((m) => m.ReferencesModule),
    canActivate: [authGuard]
  },
  {
    path: 'congress-library',
    loadChildren: () =>
      import('@prime-platform/congresses').then((m) => m.CongressesModule),
      canActivate: [authGuard]
  },
  {
    path: 'alerts',
    loadChildren: () =>
      import('@prime-platform/alerts').then((m) => m.AlertsModule),
      canActivate: [authGuard]
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('@prime-platform/resources').then((m) => m.ResourcesModule),
    canActivate: [authGuard]
  },
  {
    path: 'viewer/reference/:referenceId',
    outlet: 'modal',
    component: ViewerComponent,
    resolve: { reference: referenceResolver },
    canActivate: [authGuard]
  },
  {
    path: 'viewer/resource/:resourceId',
    outlet: 'modal',
    component: ViewerComponent,
    resolve: { resource: resourceResolver },
    canActivate: [authGuard]
  },
  {
    path: 'favorites',
    loadChildren: () =>
      import('@prime-platform/favorites').then((m) => m.FavoritesModule),
      canActivate: [authGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'inizio-login',
    component: LoginComponent,
    resolve: {route: loginFallbackResolver}
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/not-found'
  }
];
